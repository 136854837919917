<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="iconName"
    :height="height"
    :fill="fillColor"
    :width="width"
    :view-box="viewBox"
    role="presentation"
    class="icon"
  >
    <slot />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconBase',
  props: {
    iconName: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  },
})
</script>

<style lang="sass">

circle
  stroke: $c-grey-light
</style>
